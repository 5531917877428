import { SetGasStationModel } from '@/models/GasStationModel';
import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

export default class GasStationController {
  public _setGasStation(gasStation: SetGasStationModel) {
    return axios.post(`${API_URL}/gasStation`, gasStation);
  }

  public _getGasStation() {
    return axios.get(`${API_URL}/gasStation/page`);
  }

  public getGasStationById(id: number) {
    return axios.get(`${API_URL}/gasStation/${id}`);
  }

  private _updateGasStation(gasStationId: number, data: any) {
    return axios.put(`${API_URL}/gasStation/${gasStationId}`, data);
  }

  public _deleteGasStation(id: number) {
    return axios.delete(`${API_URL}/gasStation/${id}`);
  }

  public updateGasStation(id, data: any) {
    return axios.put(`${API_URL}/gasStation/${id}`, data);
  }

  public getGasStationNetworks(page, orderBy?) {
    orderBy ? '' : (orderBy = 'id');
    return axios.get(
      `${API_URL}/network/page?page=${page}&linesPerPage=24&direction=DESC&orderBy=${orderBy}`
    );
  }

  public registerGasStationNetwork(data) {
    return axios.post(`${API_URL}/network`, data);
  }

  public getNetworkById(id) {
    return axios.get(`${API_URL}/network/${id}`);
  }

  public editGasStationNetwork(id, data) {
    return axios.put(`${API_URL}/network/${id}`, data);
  }

  public gasStationsWithoutNetwork() {
    return axios.get(`${API_URL}/gasStation/all-without-network`);
  }

  public searchNetworkByName(name: string) {
    return axios.get(
      `${API_URL}/network/page?page=0&linesPerPage=10&direction=ASC&orderBy=id&name=${name}`
    );
  }

  public removeGasStationFromNetwork(gasStationId: number) {
    return axios.put(
      `${API_URL}/gasStation/${gasStationId}/desassociate-network`
    );
  }
  public updateFuelPrice(id, data) {
    return axios.put(`${API_URL}/gasStation/${id}/updateFuelPrice`, data);
  }

  public getTargetMonth(gasStationId:number, yearMonth:string){
    return axios.get(`${API_URL}/target/gas-station/${gasStationId}/findAll?yearMonth=${yearMonth}`)
  }

  public getTargetLastMonth(gasStationId: number){
    return axios.get(`${API_URL}/target/gas-station/${gasStationId}/last-target`)
  }
}
