import store from "@/store";
import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;
export default class UserModel {
  public login(email: string, pass: string, keepLoggedIn: boolean) {
    const user = {
      email: email,
      password: pass,
    };

    return axios.post(`${API_URL}/login`, user).then((res) => {
      store.dispatch("login", { token: res.data, keepLoggedIn });
      return res;
    });
  }

  refreshToken(token: any) {
    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
    return axios.post(`${API_URL}/auth/refresh_token`, token);
  }

  getUsersByType(type: string, page?: number, name?: string) {
    const url = name
      ? `${API_URL}/user/${type}/page?page=${page}&orderBy=user.name&term=${name}`
      : `${API_URL}/user/${type}/page?page=${page}&orderBy=user.name`;
    return axios.get(url);
  }
  getAttendantsGasStationId(
    gasStationId: number,
    page?: number,
    name?: string
  ) {
    const url = name
      ? `${API_URL}/user/attendant/page/attendant-gas-station?gas_station_id=${gasStationId}&page=${page}&orderBy=user.name&term=${name}`
      : `${API_URL}/user/attendant/page/attendant-gas-station?gas_station_id=${gasStationId}&page=${page}&orderBy=user.name`;
    return axios.get(url);
  }

  deleteUser(type: string, id: number) {
    return axios.delete(`${API_URL}/user/${type}/${id}`);
  }

  editUser(type: string, data: any) {
    if (type === "attendant") {
      return axios.put(`${API_URL}/user/${type}/${data.user.id}`, data);
    }

    return axios.put(`${API_URL}/user/${type}`, data);
  }

  editTargets(idTarget: number, data: any) {
    return axios.put(`${API_URL}/target/${idTarget}`, data);
  }

  recoverPassword(email: string) {
    return axios.post(`${API_URL}/auth/forgot`, { email: email });
  }

  getAllUsers() {
    return axios.get(`${API_URL}/allUsers`);
  }

  getInviteCode(clientId: number) {
    return axios.get(`${API_URL}/user/client/code?client_id=${clientId}`);
  }

  getOneUserByEmail(role: string, email: string) {
    return axios
      .get(`${API_URL}/user/${role}/email?email=${email}`)
      .then((res) => {
        if (!store.state.userName) {
          this.setStoreData(res, role);
        }
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }

  getOneUserByCPF(role: string, cpf: string) {
    return axios
      .get(`${API_URL}/user/${role}/cpf?cpf=${cpf}`)
      .then((res) => {
        if (!store.state.userName) {
          this.setStoreData(res, role);
        }
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }

  getOneUserById(role: string, id: number) {
    return axios
      .get(`${API_URL}/user/${role}/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error;
      });
  }

  setStoreData(
    data: {
      data: { user: { name: string; isCompleted: boolean }; ecopoints: string };
    },
    role: string
  ) {
    store.dispatch("setName", data.data.user.name);
    if (role === "ROLE_CLIENT" && role != null && role != undefined) {
      store.dispatch("setEcopoints", data.data.ecopoints);
      store.dispatch("setIsCompleted", data.data.user.isCompleted);
    }
  }

  comparePassword(userId: number, password: any) {
    return axios.post(
      `${API_URL}/user/${userId}/password-confirmation`,
      password
    );
  }

  getAttendantByGasStation(gasStationId: number) {
    return axios.get(`${API_URL}/user/attendant/gasStation/${gasStationId}`);
  }

  getTargetMonthByAttendant(gasStationId: number, yearMonth:string){
    return axios.get(`${API_URL}/target-attendant/findAll/${gasStationId}?yearMonth=${yearMonth}`);
  }
  
  
  getLastTargetMonthByAttendant(gasStationId: number) {
    return axios.get(`${API_URL}/target-attendant/last-target/${gasStationId}`);
  }

  setUser(type: string, data: any) {
    return axios.post(`${API_URL}/user/${type}`, data);
  }

  pajetPreRegister(cpf: number) {
    return axios.get(`${API_URL}/pajet/cpf/${cpf}`);
  }

  confirmPreRegister(data: any) {
    return axios.post(`${API_URL}/user/client/pre-register`, data);
  }
}
